import React from 'react'
import { Link } from 'react-router-dom';
// import { getDelegateInvoice,getSponsorInvoice } from '../../features/InvoiceSlice/InvoiceSlice'
import { useSelector } from 'react-redux'

import '../Invoive/Invoice.css'
// import {generatePDF} from '../../Utils/GeneratePdf'
import Header from '../../components/header/header';
const InvoiceEntry = () => {
  // const dispatch = useDispatch()
  // const location = useLocation();
  // const token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);

  // const token = searchParams.get('token');



  const { fullName,email,contact,company ,country, id, counter} = useSelector((store) => store.register)
//   useEffect(() => {
//     if(ticketType==="delegateRegistration"){
//       dispatch(getDelegateInvoice(token))

//     }else if(ticketType==="sponsorRegistration"){
//       dispatch(getSponsorInvoice(token))
//     }

//   },[])


//   useEffect(()=>{
//     if(isSuccess){
//       generatePDF(invoice)
//     }
//   },[isSuccess])


  // useContext
  return (
    <>
    <Header/>
    <section className='invoice_section'>
    <Link className="btn animate-element smallPadding" to='/'>Back to Home Page</Link>

      <div>
      <h4>You are now registered</h4>
      <p>You will receive an email with your details.</p>
      <p>Your confirmation number is:</p>
      <h5>{counter}</h5>
      </div>
      <div className='invoice_container'>

        <div className='invoice'>
          <div className='invoice_layer1'>
            <div><img src={require('../../Assets/logo/ForiLogoGrey.png')} alt="" /></div>
            <div>
              <p><strong>Invoice #</strong> {id}</p>
              <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
            </div>
          </div>

          <div className='invoice_layer1'>
            <div>
              <h6>{fullName}</h6>
              <p><strong>Email:</strong> {email}</p>
              <p><strong>Contact:</strong> {contact}</p>
              <p><strong>Company:</strong> {company}</p>
              <p><strong>Country:</strong> {country}</p>


            </div>
            <div>
              <p>Curate Tomorrow</p>
              <p>info@curatetomorrow.com</p>
              <p>Oslo, Norway</p>
            </div>
          </div>

        </div>

        {/* <button onClick={generatePDF}>Download Invoice</button> */}
      </div>
      <p>Note:A digital Invoice has been sent to your provided Email</p>

    </section>
    </>
  )
}

export default InvoiceEntry
