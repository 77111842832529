import {BrowserRouter as Router,Routes,Route,Navigate} from "react-router-dom";
import React, {Suspense}from 'react';
import { useSelector } from 'react-redux'
import './App.css';
import Home from "./pages/Home/Home"
import Footer from "./components/Footer/Footer";
import HeaderSimple from "./components/HeaderSimple/HeaderSimple";
import MobileNav from './components/MobileNav/MobileNav'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ScrollProgress from "./components/ScrollProgress/ScrollProgress"


import FormDelegate from "./pages/FormDelegate/FormDelegate"
import FormSponsor from "./pages/FormSponsor/FormSponsor"
import Main from './DashboardPages/Main/Main'
import Login from './DashboardPages/Login/Login'
// import Register from './DashboardPages/Register/Register'
import DelegatePage from './DashboardPages/DelegatePage/DelegatePage'
import SponsorPage from './DashboardPages/SponsorPage/SponsorPage'
import EmbeddedCanvafull from "./pages/CompltEvent/CompltEvent";
import FormEntry from "./pages/FormEntry/FormEntry";
import InvoiceEntry from "./pages/InvoiceEntry/InvoiceEntry";
import EntryPage from "./DashboardPages/EntryPage/EntryPage";
////lazy loading pages//////
const Speakers = React.lazy(()=> import('./pages/Speakers/Speakers'))
const About = React.lazy(()=> import('./pages/About/About'))
const Venue = React.lazy(()=> import('./pages/Venue/Venue'))
const Event = React.lazy(()=> import('./pages/Event/Event'))
const Sponsors = React.lazy(()=> import('./pages/Sponsors/Sponsors'))
const ContactPage = React.lazy(()=> import('./pages/ContactPage/ContactPage'))
const Policy = React.lazy(()=> import('./pages/Policy/Policy'))
const Accessibility = React.lazy(()=> import('./pages/Accessibility/Accessibility '))
const Invoice = React.lazy(()=> import('./pages/Invoive/Invoice'))
const CT2022 = React.lazy(()=> import('./pages/CT2022/CT2022'))
const CT2023 = React.lazy(()=> import('./pages/CT2023/CT2023'))








function App() {
  const { user } = useSelector((store) => store.auth);

  return (
    <div className="App">

      <Router>
        <HeaderSimple/>
        <MobileNav />
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/speakers" element={<Suspense fallback={<div></div>}><Speakers/></Suspense>}/>
          <Route path="/about" element={<Suspense fallback={<div></div>}><About/></Suspense>}/>
          <Route path="/venue" element={<Suspense fallback={<div></div>}><Venue/></Suspense>}/>
          <Route path="/event" element={<Suspense fallback={<div></div>}><Event/></Suspense>}/>
          <Route path="/sponsors" element={<Suspense fallback={<div></div>}><Sponsors/></Suspense>}/>
          <Route path="/contact" element={<Suspense fallback={<div></div>}><ContactPage/></Suspense>}/>
          <Route path="/policy" element={<Suspense fallback={<div></div>}><Policy/></Suspense>}/>
          <Route path="/accessibility" element={<Suspense fallback={<div></div>}><Accessibility/></Suspense>}/>
          <Route path="/invoice" element={<Suspense fallback={<div></div>}><Invoice/></Suspense>}/>
          <Route path="/entryinvoice" element={<Suspense fallback={<div></div>}><InvoiceEntry/></Suspense>}/>
          <Route path="/register-delegate" element={<FormDelegate/>}/>
          <Route path="/register-sponsor" element={<FormSponsor/>}/>
          <Route path="/checkin" element={<FormEntry/>} />
          <Route path="/event-details2023" element={<EmbeddedCanvafull/>}/>


          <Route path="/2023" element={<Suspense fallback={<div></div>}><CT2023/></Suspense>}/>
          <Route path="/2022" element={<Suspense fallback={<div></div>}><CT2022/></Suspense>}/>



          <Route path="/loginUser" element={<Login />} />
          {/* <Route path="/registerUser" element={<Register />} /> */}

          {!user ? (
          <Route path="/dashboard" element={<Navigate to="/loginUser" />} />
          ) : (
          <Route path="/dashboard" element={<Main />}>
            <Route index element={<DelegatePage/>} />
            <Route path="delegate" element={<DelegatePage />} />
            <Route path="sponsor" element={<SponsorPage />} />
            <Route path="entry" element={<EntryPage />} />
          </Route>
          )}

        </Routes>
        <Footer/>
      </Router>
      <ScrollProgress bgColor="#ffffff" gradientColor="#D22F7D"/>
      <ToastContainer />
    </div>
  );
}

export default App;
