import './bannerLine.css'




const BannerLine = () => {
return (
  <div className="heroLine" >
    <svg width="1290" height="376" viewBox="0 0 1490 376" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M693.541 163.491L912.6 18.0246" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M691.079 162.795L645.326 271.296" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M417.229 62.3014L688.617 162.099" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M248.834 181.326L282.004 158.351" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M645.325 271.296L866.074 362.09L1475.65 275.968" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M0.822754 365.592L242.284 185.679" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M547.519 183.387L644.629 273.759" stroke="black" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M693.541 163.491L912.6 18.0246" stroke="white" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M417.229 62.3014L688.617 162.099" stroke="white" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M282.004 157.768L417.232 62.3026" stroke="white" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M417.229 62.3014L546.936 182.805" stroke="white" strokeWidth="1.16454" strokeDasharray="6.99 6.99"/>
      <path d="M665.845 277.099C669.05 265.766 662.461 253.981 651.129 250.776C639.796 247.572 628.011 254.16 624.806 265.493C621.601 276.826 628.19 288.611 639.523 291.815C650.855 295.02 662.64 288.432 665.845 277.099Z" fill="#D22F7D"/>
      <path d="M1486.85 276.31C1488.45 270.644 1485.15 264.751 1479.49 263.149C1473.82 261.546 1467.93 264.841 1466.33 270.507C1464.72 276.173 1468.02 282.066 1473.68 283.668C1479.35 285.271 1485.24 281.976 1486.85 276.31Z" fill="#D22F7D"/>
      <path d="M245.931 191.587C247.533 185.922 244.239 180.03 238.573 178.427C232.908 176.825 227.015 180.119 225.413 185.785C223.811 191.451 227.105 197.343 232.771 198.945C238.437 200.547 244.329 197.253 245.931 191.587Z" fill="#D22F7D"/>
      <path d="M426.73 66.3185C428.332 60.6522 425.038 54.7597 419.371 53.1573C413.705 51.5548 407.813 54.8492 406.21 60.5155C404.608 66.1818 407.902 72.0743 413.568 73.6768C419.235 75.2792 425.127 71.9849 426.73 66.3185Z" fill="#D22F7D"/>
      <path d="M700.813 165.991C702.415 160.325 699.121 154.432 693.454 152.83C687.788 151.227 681.896 154.522 680.293 160.188C678.691 165.854 681.985 171.747 687.651 173.349C693.318 174.952 699.21 171.657 700.813 165.991Z" fill="#D22F7D"/>
      <path d="M930.199 16.3239C931.802 10.6576 928.507 4.76506 922.841 3.16259C917.175 1.56013 911.282 4.85453 909.68 10.5208C908.077 16.1871 911.372 22.0796 917.038 23.6821C922.704 25.2846 928.597 21.9902 930.199 16.3239Z" fill="#D22F7D"/>
      <path d="M875.462 364.812C877.065 359.146 873.771 353.254 868.104 351.651C862.438 350.049 856.545 353.343 854.943 359.009C853.341 364.676 856.635 370.568 862.301 372.171C867.968 373.773 873.86 370.479 875.462 364.812Z" fill="#D22F7D"/>
    </svg>
  </div>
)
}

export default BannerLine;
