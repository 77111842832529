// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heroLine{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    left: -15%;
    z-index: 4;
    top: 68%;

}
.heroLine>img{
    width: 100%;
    height: 100%;
    margin-bottom: 10%;
}
@media only screen and (max-width: 550px) {
    .heroLine{
        display: none;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/bannerLine/bannerLine.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,UAAU;IACV,UAAU;IACV,QAAQ;;AAEZ;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".heroLine{\n    position: absolute;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 50%;\n    left: -15%;\n    z-index: 4;\n    top: 68%;\n\n}\n.heroLine>img{\n    width: 100%;\n    height: 100%;\n    margin-bottom: 10%;\n}\n@media only screen and (max-width: 550px) {\n    .heroLine{\n        display: none;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
