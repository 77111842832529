import React from 'react';
import Header from '../../components/header/header';

const EmbeddedCanvafull = () => {
  return (
    <>
        <Header/>
      <div
        style={{
          position: 'relative',
          width: '85%', // Adjust the width to add padding on both sides
          height: 'auto',
          margin: '1.6em auto', // Center the content and add padding
          paddingTop: '120.4286%',
          paddingBottom: 0,
          boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)',
          overflow: 'hidden',
          borderRadius: '8px',
          willChange: 'transform',
        }}
      >
        <iframe
          title='event'
          loading="lazy"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            border: 'none',
            padding: 0,
            margin: 0,
          }}
          src="https://www.canva.com/design/DAFuWxv6WK8/view?embed"
          allowFullScreen
          allow="fullscreen"
        ></iframe>
      </div>
      <a
        href="https://www.canva.com/design/DAFuWxv6WK8/view?utm_content=DAFuWxv6WK8&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
        target="_blank"
        rel="noopener noreferrer"
        >
      </a>
    </>
  );
};

export default EmbeddedCanvafull;
